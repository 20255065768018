import React, { useState } from "react";
import {
  IResourceComponentsProps,
  BaseRecord,
  useTranslate,
  useUpdate,
  useGetIdentity,
  useCreate,
  useNotification,
} from "@refinedev/core";
import {
  useTable,
  List,
  EditButton,
  useSelect,
  DateField,
  ShowButton,
  useForm,
  DeleteButton,
  CreateButton,
} from "@refinedev/antd";
import {
  Table,
  Space,
  Button,
  Popconfirm,
  Switch,
  Card,
  Row,
  Col,
  Input,
  Select,
  Tag,
  Typography,
  Form,
  Radio,
  Checkbox,
  notification,
} from "antd";
import { IUser } from "types";
import { Link } from "react-router-dom";
import { CopyOutlined, DownloadOutlined } from "@ant-design/icons";

export const TemplateList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { tableProps, setFilters, tableQueryResult, filters, setCurrent } =
    useTable({
      syncWithLocation: true,
      filters: {
        initial: [
          {
            field: "status",
            operator: "in",
            value: ["ACTIVE", "INACTIVE"],
          },
        ],
      },
    });

  const [_f, _setF] = useState<any>();

  const roleName = localStorage.getItem("ROLE_NAME");
  const { open } = useNotification();

  const { data: user } = useGetIdentity<IUser>();
  const total = tableQueryResult.data?.total || 0;
  const maxAllow = user?.package?.maxTemplate || 0;

  const isTemplateShared = user?.package?.isTemplateShared;

  const { mutate } = useCreate();
  const { mutate: update } = useUpdate();

  const [templateCode, setTemplateCode] = useState("");
  const [importing, setImporting] = useState(false);

  return (
    <List
      headerButtons={
        <>
          {roleName == "company_manager" && (
            <>
              <Input
                placeholder={t("template.template_code")}
                onChange={(e) => setTemplateCode(e.target.value)}
                value={templateCode}
                style={{ width: 200 }}
              />
              <Button
                type="default"
                disabled={!templateCode || importing}
                onClick={() => {
                  if (templateCode) {
                    setImporting(true);
                    mutate(
                      {
                        resource: "template",
                        meta: {
                          type: "copy",
                        },
                        values: { templateCode },
                      },
                      {
                        onSuccess: () => {
                          setImporting(false);
                          setTemplateCode("");
                          setCurrent(1);
                        },

                        onError: () => {
                          setImporting(false);
                        },
                      }
                    );
                  }
                }}
              >
                <DownloadOutlined />
                {t("template.import_template")}
              </Button>
            </>
          )}
          <CreateButton hidden={roleName != "company_manager"}>
            {t("template.create_new")}
          </CreateButton>
        </>
      }
      title={
        <div>
          <Typography.Title level={4} style={{ margin: 0 }}>
            {t("template.title")}
          </Typography.Title>
          {roleName == "company_admin" && (
            <Typography.Text>
              {maxAllow ? `${total}/${maxAllow}` : ""}
            </Typography.Text>
          )}
        </div>
      }
    >
      <FilterBlock
        filters={filters}
        setFilters={(e: any) => {
          setFilters(e, "replace");
          setCurrent(1);
        }}
      />
      <br />

      <Card
        title={
          <Space>
            <Typography.Text>{t("template.label_search")}:</Typography.Text>
            <Input.Search
              allowClear
              placeholder={t("template.search")}
              onSearch={(e) => {
                setFilters([
                  {
                    field: "name",
                    operator: "contains",
                    value: e || undefined,
                  },
                ]);
                setCurrent(1);
              }}
            />
          </Space>
        }
      >
        <Table
          {...tableProps}
          rowKey="id"
          scroll={{ x: 1200 }}
          pagination={{
            ...tableProps.pagination,
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} ${t("common.of")} ${total}`,
          }}
        >
          <Table.Column
            dataIndex="name"
            width={200}
            fixed="left"
            title={t("template.template")}
            render={(v, record: any) => (
              <Link to={"/template/show/" + record.id}>{v}</Link>
            )}
          />

          <Table.Column
            dataIndex="status"
            width={120}
            title={t("template.status")}
            render={(v) => {
              if (v == "INACTIVE") {
                return <Tag color="">{t("template.status_inactive")}</Tag>;
              }
              if (v == "ACTIVE") {
                return <Tag color="blue">{t("template.status_active")}</Tag>;
              }
              if (v == "ON_HOLD") {
                return <Tag color="lime">{t("template.status_onhold")}</Tag>;
              }
            }}
          />

          <Table.Column
            dataIndex={["createdTime"]}
            title={t("common.created_at")}
            width={120}
            sorter
            render={(value: any) => <DateField value={value} />}
          />

          <Table.Column
            dataIndex={["updatedTime"]}
            title={t("common.updated_at")}
            render={(value: any) => <DateField value={value} />}
            width={140}
            sorter
          />
          <Table.Column
            dataIndex={["activeTime"]}
            sorter
            width={120}
            title={t("template.active_date")}
            render={(value: any, r: any) =>
              r.status == "ACTIVE" && value ? <DateField value={value} /> : "-"
            }
          />
          {/* {roleName == "company_admin" && ( */}
          <Table.Column
            dataIndex={["user", "fullName"]}
            width={150}
            title={t("template.created_by")}
          />
          {/* )} */}

          <Table.Column
            dataIndex={["totalQuestion"]}
            title={t("template.number_question")}
            align="right"
            width={100}
            render={(v) => v || "-"}
          />
          <Table.Column
            dataIndex={["totalUsers"]}
            title={t("template.number_use")}
            render={(v) => v || "-"}
            width={100}
            align="right"
          />
          <Table.Column
            dataIndex={["totalGroups"]}
            title={t("template.number_group")}
            render={(v) => v || "-"}
            width={100}
            align="right"
          />

          {roleName == "company_admin" && (
            <>
              <Table.Column
                title={t("template.share")}
                dataIndex="actions"
                align="center"
                width={120}
                render={(_, record: BaseRecord) => (
                  <>
                    {record.status != "ACTIVE" ? (
                      <Switch checked={record.isPublic} disabled />
                    ) : (
                      <Popconfirm
                        title={t("template.confirm_change_share")}
                        description={t("template.desc_change_share")}
                        onConfirm={() => {
                          update({
                            id: record.id ?? "",
                            resource: "template",
                            values: {
                              id: record.id ?? "",
                              status: record.status,
                              isPublic: !record.isPublic,
                            },
                          });
                        }}
                        okText={t("common.confirm_ok")}
                        cancelText={t("common.confirm_close")}
                      >
                        <Switch checked={record.isPublic} />
                      </Popconfirm>
                    )}
                  </>
                )}
              />

              <Table.Column
                title={t("template.on_hold")}
                dataIndex="actions"
                align="center"
                width={120}
                render={(_, record: any) => {
                  if (record.status == "INACTIVE") {
                    return null;
                  }
                  return (
                    <Popconfirm
                      title={t("template.confirm_change_hold")}
                      description={t("template.desc_change_hold")}
                      onConfirm={() => {
                        update({
                          id: record.id ?? "",
                          resource: "template",
                          values: {
                            id: record.id ?? "",
                            status:
                              record.status == "ON_HOLD" ? "ACTIVE" : "ON_HOLD",
                          },
                        });
                      }}
                      okText={t("common.confirm_ok")}
                      cancelText={t("common.confirm_close")}
                    >
                      <Checkbox checked={record.status == "ON_HOLD"} />
                    </Popconfirm>
                  );
                }}
              />
              {isTemplateShared && (
                <>
                  <Table.Column
                    title={t("template.public_share")}
                    dataIndex="actions"
                    align="center"
                    width={120}
                    render={(_, record: any) => {
                      if (record.status == "INACTIVE") {
                        return null;
                      }
                      return (
                        <Popconfirm
                          title={t("template.confirm_change_public_share")}
                          description={t("template.desc_change_public_share")}
                          onConfirm={() => {
                            update({
                              id: record.id ?? "",
                              resource: "template",
                              values: {
                                id: record.id ?? "",
                                isShared: !record.isShared,
                              },
                              meta: {
                                type: "shared",
                              },
                            });
                          }}
                          okText={t("common.confirm_ok")}
                          cancelText={t("common.confirm_close")}
                        >
                          <Checkbox checked={record.isShared} />
                        </Popconfirm>
                      );
                    }}
                  />

                  <Table.Column
                    title={t("template.copy_share_code")}
                    dataIndex="actions"
                    align="center"
                    width={120}
                    render={(_, record: any) => {
                      if (
                        record.status == "INACTIVE" ||
                        !record.isShared ||
                        !record.templateCode
                      ) {
                        return null;
                      }
                      return (
                        <Button
                          size="small"
                          type="text"
                          onClick={() => {
                            navigator.clipboard.writeText(record.templateCode);
                            // open?.({
                            //   message: t("common.copied"),
                            //   type: "success",
                            // });
                            notification.success({
                              message: t("common.copied"),
                            });
                          }}
                        >
                          <CopyOutlined />
                        </Button>
                      );
                    }}
                  />
                </>
              )}
            </>
          )}

          {roleName == "company_manager" && (
            <Table.Column
              width={120}
              title={t("common.action")}
              dataIndex="actions"
              fixed="right"
              align="center"
              render={(_, record: BaseRecord) => (
                <Space>
                  <EditButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                    hidden={
                      record.status == "ACTIVE" || record.status == "ON_HOLD"
                    }
                  />

                  <Popconfirm
                    title={t("template.confirm_clone")}
                    description={t("template.confirm_clone_desc")}
                    onConfirm={() => {
                      mutate({
                        resource: "template",
                        meta: {
                          type: "clone",
                        },
                        values: { id: record.id },
                      });
                    }}
                    okText={t("common.confirm_ok")}
                    cancelText={t("common.confirm_close")}
                  >
                    <Button icon={<CopyOutlined />} size="small" />
                  </Popconfirm>

                  {!record.totalUsers && !record.totalGroups ? (
                    <DeleteButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                      hidden={
                        record.status == "ACTIVE" || record.status == "ON_HOLD"
                      }
                      title={t("template.desc_delete")}
                      confirmTitle={t("template.desc_delete")}
                      confirmOkText={t("template.delete")}
                      confirmCancelText={t("template.cancel")}
                    />
                  ) : null}
                </Space>
              )}
            />
          )}
        </Table>
      </Card>
    </List>
  );
};

const FilterBlock = ({ filters, setFilters }: any) => {
  const { formProps, form } = useForm({
    warnWhenUnsavedChanges: false,
  });

  const { selectProps } = useSelect({
    resource: "user",
    optionLabel: "fullName",
    optionValue: "userId",
    pagination: {
      pageSize: 10000,
    },
  });
  const roleName = localStorage.getItem("ROLE_NAME");
  const t = useTranslate();
  return (
    <Card>
      <Form
        {...formProps}
        onValuesChange={(e) => {
          const d = form.getFieldsValue() as any;
          let statuses = ["ACTIVE", "INACTIVE"];
          if (d.status) {
            statuses = [d.status];
            if (d.status == "ALL") {
              statuses = ["ACTIVE", "INACTIVE", "ON_HOLD"];
            }
          }
          setFilters(
            [
              {
                field: "status",
                operator: "in",
                value: statuses,
              },
              {
                field: "user.userId",
                operator: "eq",
                value: d.userId,
              },
            ],
            "replace"
          );
        }}
      >
        <Row gutter={[12, 12]}>
          {roleName == "company_admin" && (
            <Col span={8}>
              <Form.Item
                style={{ margin: 0 }}
                name="userId"
                label={t("template.created_by")}
              >
                <Select
                  {...selectProps}
                  style={{ width: "100%" }}
                  placeholder={t("template.select_created_by")}
                  allowClear
                />
              </Form.Item>
            </Col>
          )}
          <Col span={8}>
            <Form.Item
              style={{ margin: 0 }}
              name="status"
              label={t("filter.status")}
            >
              <Select
                allowClear
                style={{ width: "100%" }}
                placeholder={t("filter.status_placeholder")}
                options={[
                  { value: "ALL", label: t("common.all") },
                  { value: "ACTIVE", label: t("common.actived") },
                  { value: "INACTIVE", label: t("common.inactived") },
                  { value: "ON_HOLD", label: t("common.on_hold") },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
